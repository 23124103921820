





























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import DummySpan from './DummySpan.vue';
import {isTruthy} from '../util/watcher.vue-decorator';
@Component({ components: { DummySpan } })
export default class BaseModal extends Vue {
  @Prop(String) public title!: string;
  @Prop(String) public modalBodyClass!: string;
  @Prop({ type: Boolean, default: false })
  public noFooter!: boolean;
  @Prop(Boolean) public usePortal!: boolean;
  @Prop(Boolean) public notScrollable!: boolean;
  @Prop({ type: String, default: 'small' })
  public size!: string;
  public isOpen = false;
  @Prop({ type: Boolean, default: false })
  public isModalOpen!: boolean;
  public closeModal() {
    this.isOpen = false;
  }

  @Watch('isOpen')
  public watchIsOpen() {
    if (this.isOpen) {
      this.$emit('open');
    } else {
      this.$emit('close');
      this.$emit('update:isModalOpen', false);
    }
  }

  @Watch ('isModalOpen')
  @isTruthy
  public watchIsModalOpen() {
    this.isOpen = true;
  }

  public created() {
    if (this.isModalOpen === true) {
      this.isOpen = true;
    }
  }
  get modalWrapperComponent() {
    return this.usePortal ? 'portal' : 'DummySpan';
  }
}
