




























































import { Watch, Prop } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import Component, { mixins } from 'vue-class-component';
import { RootState } from '@/store/store';
import RadialProgressBar from '@/jbi-shared/vue-components/RadialProgressBar.vue';
import UploadedInvitedUserTable from './UploadedInvitedUserTable.vue';
import { isTruthy, isDifferent } from '@/jbi-shared/util/watcher.vue-decorator';
import { ToastProgrammatic as Toast } from 'buefy';
import {
  BulkInviteUserToProductPayload,
  CheckProductStatus,
  JobResultStatus
} from '@/store/modules/admin/types/admin.types';
import { ValidateImportedUserListPayload } from '@/store/modules/job-result-data/job-result-data.types';
import { InvitationHandler } from './invitation.mixin';
import CsvImportGuide from '@/components/CsvImportGuide.vue';
import FileUpload from '@/components/FileUpload.vue';
import { CsvXlxs } from '../../../../utils/csv-xlxs.util';
import { handleUserInviteListUploading } from '@/utils/invite-users-upload.util';
import { isJobFailure } from '@/utils/bull-queue.util';

const fileTypeOptions = [
  { name: 'Microsoft Excel Spreadsheet (.xlsx)', value: 'xlsx' },
  { name: 'Comma-separated values (.csv)', value: 'csv' }
];

@Component({
  components: {
    RadialProgressBar,
    UploadedInvitedUserTable,
    CsvImportGuide,
    FileUpload
  }
})
export default class GenerateInvitationForm extends mixins(InvitationHandler) {
  @Prop() public products!: object;
  public isLoading = false;

  public dropFiles: File[] | null = null;
  public fileData: JobResultStatus | null = null;
  public isFileDataError: boolean = false;
  public selectedFileType: string = fileTypeOptions[0].value;

  get fileTypeOptions() {
    return fileTypeOptions;
  }

  @State(
    (state: RootState) => state.admin.apiState.generateInvitationLinks.success
  )
  public generateInvitationLinksSuccess!: boolean;

  @Action('admin/generateInvitationLinks')
  public generateInvitationLinks!: (
    data: BulkInviteUserToProductPayload
  ) => Promise<any>;

  @Action('admin/uploadUserListFile')
  public uploadUserListFile!: (file: File) => Promise<any>;

  @Action('jobResultData/createImportedListValidationJob')
  public validateUserInvite!: (
    payload: ValidateImportedUserListPayload
  ) => Promise<any>;

  public resetFile() {
    this.fileData = null;
    this.dropFiles = null;
  }

  isUserListValid(isValid: boolean) {
    if (!this.fileData) {
      this.$emit('isUserListValid', false);
    } else {
      this.$emit('isUserListValid', isValid);
    }
  }

  @Watch('fileData')
  public updateFileDataError() {
    this.isFileDataError =
      this.fileData !== null &&
      'jobId' in this.fileData &&
      'status' in this.fileData &&
      'message' in this.fileData;
  }

  @Watch('generateInvitationLinksSuccess')
  @isDifferent
  @isTruthy
  public async watchBulkInviteSuccess(value: File[]) {
    this.$emit('closeModal');
  }

  @Watch('dropFiles')
  @isTruthy
  public async onFilesAdded(file: File) {
    const isCsv = CsvXlxs.isCsv(file);
    const isXlsx = CsvXlxs.isXlsx(file);

    try {
      if (isCsv || isXlsx) {
        const gcsURL = await this.uploadUserListFile(file);

        const job = await this.validateUserInvite({
          fileLink: gcsURL,
          emailIndex: 2
        });

        this.fileData = (await handleUserInviteListUploading.call(
          this,
          job
        )) as JobResultStatus;

        if (isJobFailure(this.fileData)) {
          this.resetFile();
        }

        this.$emit('uploadComplete', true);
        if (!this.fileData) {
          this.$emit('isUserListValid', false);
        }
      } else {
        throw Error(`Invalid File Format`);
      }
    } catch (error: any) {
      Toast.open({
        queue: true,
        type: 'is-danger',
        position: 'is-top',
        message: error?.response?.data?.message || error,
        duration: 5000
      });
    }
  }

  public async handleGenerate() {
    if (!this.fileData || isJobFailure(this.fileData)) {
      this.isLoading = false;
      return;
    }
    this.isLoading = true;

    const checkProductStatus = this.fileData
      .filter((product) => !!product.products)
      .map((product) => {
        const checkProduct = product.products?.find(
          (usedProduct) => usedProduct.productId === product.productId
        );
        if (!!checkProduct && Math.sign(checkProduct.redeemable) === -1) {
          return {
            ...product,
            productStatus: 'excluded'
          };
        } else {
          return {
            ...product
          };
        }
      }) as CheckProductStatus[];

    if (
      checkProductStatus.filter((product) => !!product.productStatus).length > 0
    ) {
      this.isLoading = false;
      this.$emit('watchGenerateInviteError');
      return;
    }

    const inviteUserFile = await this.generateInvitationLinks({
      invitations: this.fileData
    });
    this.isLoading = false;

    if (this.selectedFileType === 'csv') {
      return this.downloadAsCsv(inviteUserFile);
    } else if (this.selectedFileType === 'xlsx') {
      return this.downloadAsXlsx(inviteUserFile);
    }
  }

  public handleEnableGenerateLink() {
    this.$emit('uploadComplete');
  }

  public handleDisableGenerateLink() {
    this.$emit('disableGenerateLink');
  }
}
